import { genButton, genContent, genLocalImage } from '@/helpers/utils'
import banner from '~/public/assets/images/homepage/v4/Banner.svg'
import { parsedContent } from '@/lib/utils'

export const HOME_BANNER_CONSTANTS = {
  content_col_size: 7,
  button: genButton(`Get Started With Us`, '/pricing', {
    marginTop: { xs: 5 },
    backgroundColor: '#fff',
    hoverColor: '#3D50FC !important',
    padding: { xs: '18px 26px !important', md: '20px 30px !important' },
    textColor: '#000',
    minWidth: 255,
    [':hover']: {
      background: '#3D50FC !important',
      color: '#fff !important'
    }
  }),
  slug: parsedContent(
    `<h1 data-aos="fade-up" class="text-white text-8 2xl:text-[64px] xl:text-[46px] md:text-[28px] text-[28px] 2xl:leading-[64px] xl:leading-[46px] md:leading-[28px] leading-[28px] font-normal font-inter">
           <span class="mb-3 md:mb-2 block">All-In-One</span>
           <span class="text-white 2xl:text-[96px] xl:text-[72px] md:text-[36px] text-[36px] 2xl:leading-[96px] xl:leading-[72px] md:leading-[36px] leading-[36px] font-bold lg:font-semibold font-intermb-1.5">FinTech Platform</span>
          </h1>`,
    ''
  ),
  title: genContent(
    `<span class="text-[28px] 2xl:text-[66px] xl:text-[46px] mt-3 md:text-[28px] 2xl:leading-[66px] xl:leading-[46px] md:leading-[28px] font-bold lg:font-semibold text-white  font-inter">For Effortless </span>`,
    {
      maxWidth: 900
    }
  ),
  type_writer: {
    classes: '',
    words: ['Invoicing', 'Checkbook', 'Text To Pay', 'POS Solution', 'Payments'],
    delay: 100
  },
  subtitle: genContent(
    `With cutting-edge AI technology our innovative platform leverages digital invoicing, checkbook, text-to-pay, & merchant services.`,
    {
      maxWidth: { xl: '776px', lg: '550px', md: '440px', xs: '442px' },
      // mb: { xs: '6px', sm: '6px', lg: '10px', xl: '0px' },
      fontSize: { xs: '18px', lg: '24px' },
      lineHeight: { xs: '26px', lg: '34px' }
    }
  ),
  img: genLocalImage(
    banner,
    'Welcome to PayToMe: Revolutionizing Contactless Payments',
    { maxWidth: { lg: 630, xl: 630, md: 450, sm: 450 }, maxHeight: 560 },
    {
      maxWidth: { lg: 630, xl: 630, md: 450, sm: 450 },
      justifyContent: { xs: 'center', lg: 'center' }
    }
  ),
  willHaveGradientShade: true
}
