import React from 'react'
import BannerWithTypeWriter from '@/components/home/banner/includes/BannerWithTypeWriter'
import useClassnames from '@/hooks/tsx/use-classnames'
import { HOME_BANNER_CONSTANTS } from '@/constants/home'
import useResponsive from '@/hooks/responsive'

const HomeBanner = () => {
  const bannerClassNames = useClassnames()
  const { mobileAll } = useResponsive()

  return (
    <>
      <BannerWithTypeWriter
        data={{ ...HOME_BANNER_CONSTANTS, willHaveGradientShade: !mobileAll }}
        classNames={bannerClassNames}
      />
    </>
  )
}

export default HomeBanner
