import { cn } from '@/lib/utils'
import useResponsive from '@/hooks/responsive'
import { KeyValuePairs } from '@/types/global'

function useClassNames(rest = {}) {
  const { desktopUp, laptop, highResolution, mobileAll } = useResponsive()

  return {
    section: cn(
      'bg-rainbow'
      // desktopUp && 'min-h-[575px]',
      // laptop && 'min-h-[890px]',
      // highResolution && 'min-h-[913px]',
      // mobileAll && 'min-h-[800px]'
    ),
    // img: cn('', highResolution && 'right-[8%]'),
    img: cn('', highResolution && ''),
    imgShade: cn(
      ''
      // desktopUp && 'bottom-[-110px] [&>svg]:w-[560px]',
      // laptop && 'bottom-[-4px] [&>svg]:w-[806px]',
      // highResolution && 'bottom-[-27px] opacity-30'
    ),
    title: cn('', highResolution && ''),
    slug: cn('', highResolution && ''),
    subtitle: cn('', highResolution && ''),
    ...rest
  }
}

export default useClassNames
