import React from 'react'
import { SectionHeader } from '@/v1/@core/section-header'
import { parsedContent } from '@/lib/utils'
import { genLocalImage } from '@/helpers/utils'
import BusinessSolution from '~/public/assets/images/homepage/v4/business-solution.png'
import BusinessSolution1 from '~/public/assets/images/homepage/v4/business-solution-1.png'
import BusinessSolution2 from '~/public/assets/images/homepage/v4/business-solution-2.png'
import BusinessSolution3 from '~/public/assets/images/homepage/v4/business-solution-3.png'
import BusinessSolution4 from '~/public/assets/images/homepage/v4/business-solution-4.png'
import GreenCheckMark from '~/public/assets/images/homepage/v4/green-check-mark.svg'
import CallToActionButton from '@/v1/@core/CallToActionButton'
import IconLists from '@/helpers/IconLists'
import { AnimatePresence } from 'framer-motion'
import Image from 'next/image'
import useResponsive from '@/hooks/responsive'

const contents = [
  {
    thumb: GreenCheckMark,
    title: 'Account Receivable',
    url: '/accounts-receivables',
    description:
      "Manage your Accounts Receivable efficiently with PayToMe's online invoice management platform. Use our Text-to-Pay and Payment Link features to expedite payments and balance your AR swiftly."
  },
  {
    thumb: GreenCheckMark,
    title: 'Account Payable',
    url: '/accounts-payable-services',
    description:
      "Streamline your Accounts Payable process with PayToMe's efficient payment solutions. Our platform ensures timely bill payments and vendor management, helping you maintain strong business relationships and financial health."
  },
  {
    thumb: GreenCheckMark,
    title: 'Expense Management',
    url: '/expense-management',
    description:
      "Boost financial oversight with PayToMe's Expense Management System. Easily track and manage spending, ensure budget adherence, and improve fiscal operations with our user-friendly platform."
  },
  {
    thumb: GreenCheckMark,
    title: 'Business Banking',
    url: '',
    description:
      'Enhance your business banking. Our platform supports seamless global money transfers and robust banking solutions, allowing you to open an online business checking account quickly and efficiently.'
  }
]

const HomeFeature = () => {
  const text62 = 'xl:text-[62px] text-[28px]'
  const { allMobileTab } = useResponsive()

  return (
    <>
      <div className='bg-indigo-50'>
        <div className='container section-spacing'>
          <SectionHeader
            titlePrefix={parsedContent('Business ', text62)}
            title={parsedContent(`Solution & Platforms`, text62)}
            isInlineTitle
          />

          <div className='mb-3 flex flex-col items-center gap-2 last:mb-0 md:mb-8 xl:flex-row xl:gap-3'>
            <div className={`w-[100%]} mb-8 md:mb-8 xl:mb-0 xl:w-[48%]`}>
              <div
                className={'relative z-0 mx-auto h-auto max-h-[562px] w-full max-w-[90%] md:max-w-[80%] lg:max-w-[65%]'}
              >
                <Image
                  data-aos='zoom-in'
                  data-aos-delay={300}
                  data-aos-duration={1200}
                  src={genLocalImage(BusinessSolution).src}
                  alt={'Business solution'}
                  priority
                  className={'h-auto w-full'}
                />

                <div className={'absolute left-[-15%] top-[0%] max-w-[30%] xs:left-[-8%]'}>
                  <Image
                    data-aos={!allMobileTab && 'fade-right'}
                    data-aos-delay={!allMobileTab && 300}
                    data-aos-duration={!allMobileTab && 1500}
                    data-aos-easing={!allMobileTab && 'ease-in-sine'}
                    src={genLocalImage(BusinessSolution1).src}
                    alt={'Business solution'}
                    priority
                    className={'h-auto w-full'}
                  />
                </div>

                <div className={'absolute right-[-10%] top-[5%] max-w-[30%] xs:right-[-5%]'}>
                  <Image
                    data-aos={!allMobileTab && 'fade-left'}
                    data-aos-delay={!allMobileTab && 300}
                    data-aos-duration={!allMobileTab && 1500}
                    data-aos-easing={!allMobileTab && 'ease-in-sine'}
                    src={genLocalImage(BusinessSolution2).src}
                    alt={'Business solution'}
                    priority
                    className={'h-auto w-full'}
                  />
                </div>

                <div className={'absolute bottom-[-5%] left-[-20%] max-w-[25%] xs:left-[-8%]'}>
                  <Image
                    data-aos={!allMobileTab && 'fade-right'}
                    data-aos-delay={!allMobileTab && 300}
                    data-aos-duration={!allMobileTab && 1500}
                    data-aos-easing={!allMobileTab && 'ease-in-sine'}
                    src={genLocalImage(BusinessSolution3).src}
                    alt={'Business solution'}
                    priority
                    className={'h-auto w-full'}
                  />
                </div>

                <div className={'absolute bottom-[-5%] right-[-15%] z-50 max-w-[30%] xs:right-[-5%]'}>
                  <Image
                    data-aos={!allMobileTab && 'fade-left'}
                    data-aos-delay={!allMobileTab && 300}
                    data-aos-duration={!allMobileTab && 1500}
                    data-aos-easing={!allMobileTab && 'ease-in-sine'}
                    src={genLocalImage(BusinessSolution4).src}
                    alt={'Business solution'}
                    priority
                    className={'h-auto w-full'}
                  />
                </div>
              </div>
            </div>
            <div className={`w-[100%]} xl:w-[52%]`}>
              {contents?.map((content, index) => (
                <AnimatePresence key={index} mode='wait'>
                  <div
                    data-aos='flip-down'
                    data-aos-easing='linear'
                    data-aos-delay={400 + index * 50}
                    data-aos-duration={1000 + index * 80}
                    className='mb-4 flex flex-row items-start gap-1 xl:mb-5 xl:gap-5'
                  >
                    <div className='thumb mt-1 w-[6%] xl:mt-0 xl:w-[5%]'>
                      <Image
                        priority
                        src={genLocalImage(content?.thumb).src}
                        alt={content?.title}
                        className='h-auto w-full'
                      />
                      {/*<Image src={genLocalImage(content?.thumb).src} alt={content?.title} />*/}
                    </div>
                    <div className='content w-[95%]'>
                      <h3 className={'text-[18px] font-semibold text-blue-cyan xl:text-[24px]'}>{content?.title}</h3>
                      <div className={''}>
                        <p className={'text-[16px] font-normal text-twilight xl:text-[20px]'}>
                          {content?.description}
                          {content?.url ? (
                            <a
                              href={content.url}
                              target='_blank'
                              className={'ml-1 text-[16px] font-medium text-blue-clear xl:text-[20px]'}
                            >
                              Learn More {'->'}
                            </a>
                          ) : (
                            <span
                              className={'ml-1 cursor-pointer text-[16px] font-medium text-blue-clear xl:text-[20px]'}
                            >
                              Learn More {'->'}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </AnimatePresence>
              ))}

              <div
                className='mt-6 flex items-center justify-center sm:mt-8 sm:justify-start sm:px-2'
                // data-aos='fade-right'
                // data-aos-delay={400}
                // data-aos-duration={1000}
                // data-aos-easing='ease-in-sine'
              >
                <CallToActionButton
                  className={'sm:ml-[50px]'}
                  href={'/pricing'}
                  text={'Get Started'}
                  icon={IconLists.bigRightArrowIcon()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeFeature
