import React from 'react'
import useResponsive from '@/hooks/responsive'
import { SectionHeader } from '@/v1/@core/section-header'
import { parsedContent } from '@/lib/utils'
import { genLocalImage } from '@/helpers/utils'
import IconList from '@/constants/IconList'
import { motion, useAnimation } from 'framer-motion'
import { useEffect, useState } from 'react'
import PtmCardMachine from '~/public/assets/images/homepage/v4/PtmCardMachine.png'
import PersonPayments from '~/public/assets/images/homepage/v4/person-payments.svg'
import OnlinePayments from '~/public/assets/images/homepage/v4/online-payments.svg'
import MerchantFee from '~/public/assets/images/homepage/v4/merchant-fee.svg'
import MerchantPricing from '~/public/assets/images/homepage/v4/merchant-pricing.svg'

import CallToActionButton from '@/v1/@core/CallToActionButton'
import IconLists from '@/helpers/IconLists'
import Image from 'next/image'

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.3
    }
  }
}

const item = {
  hidden: { y: 0, opacity: 0 }, // Start from a lower position and opacity
  visible: {
    y: 0, // Slide up to the original position
    opacity: 1,
    transition: { duration: 0.9 } // Add a duration to make the animation slower
  }
}

const HoverableListItem = ({ imageSrc, altText, labelText, initialMarginLeft, hoverMarginLeft }) => {
  const [marginLeft, setMarginLeft] = useState(initialMarginLeft)
  const controls = useAnimation()

  const handleHover = () => {
    controls.start({ marginLeft: hoverMarginLeft })
  }

  const handleHoverExit = () => {
    controls.start({ marginLeft: initialMarginLeft })
  }

  useEffect(() => {
    controls.set({ marginLeft: initialMarginLeft })
  }, [initialMarginLeft])

  return (
    <motion.li
      className='ml-[${marginLeft}px] mb-3 flex h-[60px] w-full items-center gap-1 rounded-[15px] bg-[#ECF3FE] px-3 text-blue-cyan transition delay-150 ease-in-out hover:bg-[#7E86FD] hover:text-[#fff] xl:mb-6 xl:h-[70px] xl:w-[285px]'
      animate={{ marginLeft: initialMarginLeft }}
      whileHover={{ marginLeft: hoverMarginLeft }}
      variants={item}
    >
      <span className={'max-w-[55px]'}>
        <Image priority src={imageSrc} alt={altText} className='h-auto w-full' />
      </span>
      <span className={'text-lg font-medium xl:text-xl'}>{labelText}</span>
    </motion.li>
  )
}

const HomeFeature = () => {
  const { tabLandscape } = useResponsive()

  const text62 = 'xl:text-[62px] text-[28px]'

  return (
    <>
      <div className='bg-white-50'>
        <div className='container section-spacing'>
          <SectionHeader
            titlePrefix={parsedContent('We Build ', text62)}
            title={parsedContent(`Merchant Services`, text62)}
            titlePart={parsedContent(`Smarter and Simpler`, text62)}
            isInlineTitle
          />

          <div className='mb-5 mt-0 flex flex-col items-center gap-5 md:mb-8 md:mt-4 md:flex-row'>
            <div className={`w-[100%]} mb-0 md:mb-0 md:w-[50%]`}>
              <h3 className='mb-3 font-inter text-lg font-bold text-blue-cyan xl:mb-4 xl:text-[36px]'>
                Simplify Your Payment Process
              </h3>

              <p className='mb-4 block text-base text-twilight xl:mb-5 xl:text-xl'>
                Start accepting payments smoothly and securely today! Our merchant accounts are perfect for businesses
                operating both in-person and online. With our easy setup, and competitive rates, take advantage of:
              </p>

              <ul className=''>
                <li
                  data-aos={tabLandscape ? 'fade-up' : 'fade-right'}
                  data-aos-easing='linear'
                  data-aos-delay={300}
                  data-aos-duration={800}
                  className='mb-2 flex items-center gap-2 text-lg font-semibold text-blue-cyan xl:mb-2 xl:text-xl'
                >
                  <span>{IconList.greenTickIcon}</span> Straight Forward Merchant Pricing
                </li>
                <li
                  data-aos={tabLandscape ? 'fade-up' : 'fade-left'}
                  data-aos-easing='linear'
                  data-aos-delay={300}
                  data-aos-duration={1100}
                  className='mb-2 flex items-center gap-2 text-lg font-semibold text-blue-cyan xl:mb-2 xl:text-xl'
                >
                  <span>{IconList.greenTickIcon}</span> Fast Merchant Approval
                </li>
                <li
                  data-aos={tabLandscape ? 'fade-up' : 'fade-right'}
                  data-aos-easing='linear'
                  data-aos-delay={300}
                  data-aos-duration={1500}
                  className='mb-2 flex items-center gap-2 text-lg font-semibold text-blue-cyan xl:mb-2 xl:text-xl'
                >
                  <span>{IconList.greenTickIcon}</span>24/7 Customer Support
                </li>
              </ul>
            </div>

            <div className={`relative mb-0 h-full w-[100%] md:mb-0 md:w-[50%]`}>
              <motion.ul variants={container} initial='hidden' animate='visible'>
                <HoverableListItem
                  imageSrc={genLocalImage(PersonPayments).src}
                  altText='PTM Card Machine'
                  labelText='In-Person Payments'
                  initialMarginLeft={!tabLandscape ? 44 * 4 : 0}
                  hoverMarginLeft={44 * 3.5}
                />
                {/* Second List Item */}
                <HoverableListItem
                  imageSrc={genLocalImage(OnlinePayments).src}
                  altText='PTM Card Machine'
                  labelText='Online Payments'
                  initialMarginLeft={!tabLandscape ? 32 * 4 : 0}
                  hoverMarginLeft={32 * 3.5}
                />
                {/* Third List Item */}
                <HoverableListItem
                  imageSrc={genLocalImage(MerchantFee).src}
                  altText='PTM Card Machine'
                  labelText='0% Merchant Fee'
                  initialMarginLeft={!tabLandscape ? 20 * 4 : 0}
                  hoverMarginLeft={20 * 3.5}
                />
                {/* Fourth List Item */}
                <HoverableListItem
                  imageSrc={genLocalImage(MerchantPricing).src}
                  altText='PTM Card Machine'
                  labelText='Merchant Pricing'
                  initialMarginLeft={!tabLandscape ? 8 * 5 : 0}
                  hoverMarginLeft={8 * 3.5}
                />
              </motion.ul>

              <div className='mt-5 flex items-center justify-center xl:mt-0 xl:px-8'>
                <CallToActionButton
                  className={
                    'flex h-[60px] w-full justify-between rounded-[16px] bg-[linear-gradient(90deg,_#082955_0%,_#656FFB_100%)] md:h-[72px] xl:justify-start'
                  }
                  // href={'/register?plan=ok6yhw9ah'}
                  href={`/register/business-lite`}
                  text={'Open Account'}
                  icon={IconLists.bigRightArrowIcon()}
                />
              </div>
              {!tabLandscape && (
                <div className={'absolute right-12 top-[-15%]'}>
                  <div className={'h-auto w-full max-w-[200px]'}>
                    <Image
                      src={genLocalImage(PtmCardMachine).src}
                      alt={`PTM Card Machine`}
                      priority
                      className={'h-auto w-full'}
                      data-aos='zoom-in'
                      data-aos-delay={300}
                      data-aos-duration={800}
                      data-aos-anchor-placement='top-bottom'
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeFeature
